.date-picker-input {
  // default styles from the library
  // will be removed in https://linear.app/joinfluffy/issue/INSR-230
  @import "react-calendar/dist/Calendar";

  width: 260px;
  // todo: create colors tokens in https://linear.app/joinfluffy/issue/INSR-231
  border-bottom: 2px solid rgba(89, 56, 34, 0.32); // GreySemiLight

  &:active {
    border-color: #e98310; // Orange
  }

  .react-date-picker__calendar {
    z-index: 30 !important;
  }

  .react-date-picker__wrapper {
    border: none !important;

    .react-date-picker__inputGroup {
      font-size: 24px;
      line-height: 36px;

      input:focus {
        outline: none;
      }

      &__input:invalid {
        background-color: rgba(233, 131, 16, 0.12); // OrangePale
      }
    }

    .react-date-picker__button:enabled:hover .react-date-picker__button__icon {
      stroke: #e98310 !important; // Orange
    }
  }

  .react-calendar {
    padding: 8px;
    border: none !important;
    border-radius: 12px;
    box-shadow: 2px 2px 8px rgba(89, 56, 34, 0.32); // GreySemiLight

    // common style for all interactive elements
    .react-calendar__navigation,
    .react-calendar__viewContainer {
      button {
        &:enabled {
          &:focus {
            background-color: transparent;
          }

          &:hover {
            background-color: rgba(233, 131, 16, 0.12); // OrangePale
          }
        }

        &:disabled {
          cursor: default;
        }
      }
    }

    // Navigation block
    .react-calendar__navigation {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 12px;

      .react-calendar__navigation__arrow,
      .react-calendar__navigation__label {
        height: 32px;
        padding: 4px;
        border: none;
        border-radius: 8px;
        margin: 0 4px;
        box-sizing: border-box;
      }

      .react-calendar__navigation__arrow {
        flex-shrink: 0;
        min-width: 32px;
        width: 32px;

        &:enabled {
          box-shadow: 1px 1px 6px rgba(89, 56, 34, 0.12);
        }
      }

      .react-calendar__navigation__label {
        flex: 1;

        &:enabled:hover {
          box-shadow: 1px 1px 6px rgba(89, 56, 34, 0.12);
        }
      }
    }

    // Block with weekdays labels
    .react-calendar__month-view__weekdays__weekday {
      font-weight: 500;
      color: rgba(89, 56, 34, 0.55);

      abbr {
        text-decoration: none;
        border-bottom: none;
      }
    }

    // Days / months / years line (for different views: month / year / decade)
    .react-calendar__month-view__days,
    .react-calendar__year-view__months,
    .react-calendar__decade-view__years {
      justify-content: space-between;
    }

    // All tiles of the calendar (for different views: month / year / decade)
    .react-calendar__tile {
      border-radius: 8px;

      &--now:not(:disabled) {
        background-color: transparent;
      }

      &--active,
      &--hasActive {
        &,
        &:hover {
          color: #fff;
          background-color: #e98310 !important;
        }
      }

      &.react-calendar__month-view__days__day {
        flex: 0 0 13% !important;
        padding: 12px 8px;
        margin: 2px 0;
      }

      &.react-calendar__year-view__months__month,
      &.react-calendar__decade-view__years__year {
        flex: 0 0 32% !important;
        padding: 20px 4px;
        margin: 2px 0;
      }
    }

  }
}
