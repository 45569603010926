.toaster-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column-reverse;
  padding: 12px;
  z-index: 1500;
  pointer-events: none; // is important not to overlay the entire content
}

.toast-item {
  position: relative;
  width: 280px;
  padding: 20px;
  border-radius: 8px;
  background-color: rgba(89, 56, 34, 0.9); // Colors.Brown 90%
  box-shadow: 0 8px 12px -4px rgba(89, 56, 35, 0.2); // Colors.Brown 20%
  overflow-wrap: break-word;
  pointer-events: all; // is important to be able to handle user-click
  cursor: pointer;
  user-select: none;
  opacity: 0;
  transition: opacity 0.5s ease-in-out; // Set the duration and easing function as needed
}

.appear {
  opacity: 1;
}

.disappear {
  opacity: 0;
}