@mixin policy-start-date-calendar {
  .react-date-picker__wrapper {
    display: none;
  }

  .react-calendar {
    justify-content: center;
    width: 336px;
    padding: 20px 0px 20px 20px;
    background: #fdecdd;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    line-height: 24px;
    border-radius: 25px;
    text-decoration-line: none;
  }

  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .react-calendar__month-view {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .react-calendar button:enabled:hover {
    cursor: pointer;
  }

  .react-calendar__navigation {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 25px;
    margin-bottom: 20px;
  }

  .react-calendar__navigation__prev2-button,
  .react-calendar__navigation__next2-button {
    visibility: hidden;
  }

  .react-calendar__navigation__next-button {
    border-right-color: transparent;
    width: 25px;
    height: 25px;
  }

  .react-calendar__navigation__label {
    position: relative;
    align-self: start;
    left: 0;
    font-size: 19px;
    line-height: 24px;
    font-weight: 500;
    color: #000;
    pointer-events: none;
  }

  .react-calendar__navigation button {
    width: 25px;
    background: none;
  }

  .react-calendar__navigation button:disabled {
    visibility: hidden;
  }

  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
  }

  .react-calendar__tile {
    flex-basis: 0;
    border-radius: 25px;
    max-width: 25px;
    width: 25px;
    height: 25px;
    margin-top: 13px;
    margin-right: 20px;
    line-height: 24px;
    background-color: #ff7a00;
    color: #fff;
  }

  .react-calendar__tile:disabled {
    color: #000;
    background-color: #d9d9d9;
  }

  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: #4cd964;
  }

  .react-calendar__tile--hasActive {
    background: #76baff;
  }

  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
  }

  .react-calendar__tile--active {
    background: #4cd964;
    color: white;
  }

  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #4cd964;
  }

  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
  }

  .react-datetime-picker__calendar--open,
  .react-datetime-picker__calendar--closed,
  .react-datetime-picker__clock--open,
  .react-datetime-picker__clock--closed {
    position: absolute;
  }

  .react-calendar__month-view__weekdays__weekday {
    max-width: 24px;
    margin-right: 21px;
    font-size: 15px;
    line-height: 24px;
    font-weight: 500;
    color: #000;
  }
}
