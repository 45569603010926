.intercom-launcher {
    display: none;
}

.intercom-launcher-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 148px;
    padding: 12px 20px;
    border-radius: 40px;
    background-color: #e98310;
    box-sizing: border-box;
    z-index: 20;

    &__floating {
        z-index: 30;
    }
}

.intercom-launcher-container:hover {
    cursor: pointer;
    box-shadow: 0 8px 12px -4px rgb(0 0 0 / 5%);
    transform: scale(1.02);
}

.intercom-launcher-label {
    width: 95px;
    font-size: 20px;
    line-height: 26px;
    color: #fff;
    font-weight: 700;
    text-align: left;
}

.intercom-launcher-icon {
    width: 40px;
    height: 40px;
}

@media screen and (max-width: 420px) {
    .intercom-launcher-container {
        width: 124px;
        padding: 8px 16px;

        &__floating {
            bottom: 100px;
        }
    }
}
